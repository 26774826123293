import React from 'react';

const Resume = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <iframe 
                src="/Resume_Swapnil_Surdi.pdf" 
                width="80%" 
                height="800px"
                style={{ border: "none" }}
            ></iframe>
        </div>
    );
};

export default Resume;