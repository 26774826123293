import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import "./PortfolioJob.css";
import * as Constants from "../Constants/Constants";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const PortfolioItem = ({ card, parallax, isCardView }) => {
  const itemRef = useRef(null);

  useEffect(() => {
    if (parallax && !isCardView) {
      gsap.fromTo(
        itemRef.current,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          ease: "power2.out",
          scrollTrigger: {
            trigger: itemRef.current,
            start: "top 80%",
            end: "bottom 50%",
            scrub: 1,
            pin: false,
            markers: false,
          },
        }
      );
    }
  }, [parallax, isCardView]);

  return isCardView ? (
    <Card className="portfolio-card">
      <Card.Img variant="top" src={card.img} style={{ height: "250px", objectFit: "cover" }} />
      <Card.Body>
        <Card.Title>{card.title}</Card.Title>
        <Card.Text>{card.description}</Card.Text>
      </Card.Body>
    </Card>
  ) : (
    <div ref={itemRef} className={`portfolio-item ${parallax ? "parallax-item" : ""}`}>
      <div className="portfolio-content">
        <img src={card.img} alt={card.title} className="portfolio-image" />
        <div className="portfolio-text">
          <h3>{card.title}</h3>
          <p>{card.description}</p>
        </div>
      </div>
    </div>
  );
};

const Portfolio = () => {
  const [parallaxEnabled, setParallaxEnabled] = useState(false);
  const [isCardView, setIsCardView] = useState(true);

  return (
    <div>
      <div style={{ textAlign: "center", margin: "20px 0" }}>
        <Button onClick={() => setParallaxEnabled(!parallaxEnabled)} style={{ marginRight: "10px" }}>
          {parallaxEnabled ? "Switch to Simple View" : "Switch to Parallax View"}
        </Button>
        <Button onClick={() => setIsCardView(!isCardView)}>
          {isCardView ? "Switch to Advanced View" : "Switch to Card View"}
        </Button>
      </div>
      <Row className="portfolio-grid">
        {Constants.PORTFOLIO_JOB.map((card, i) => (
          <Col md="6" key={i} className="portfolio-col">
            <PortfolioItem card={card} parallax={parallaxEnabled} isCardView={isCardView} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Portfolio;
